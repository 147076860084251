
function WorkCard(props) {

    return (
        <div className="WorkCard">
            <div className="img-wrapper">
                <a href={props.url} target="_blank" rel="noopener noreferrer">
                    <img src={props.img} alt="img" className="img" />
                    {props.url? <div className="icon-wrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-external-link" width="18" height="18" viewBox="0 0 24 24" strokeWidth="1.8" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <desc>Download more icon variants from https://tabler-icons.io/i/external-link</desc>
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5"></path>
                            <line x1="10" y1="14" x2="20" y2="4"></line>
                            <polyline points="15 4 20 4 20 9"></polyline>
                        </svg>
                    </div> : null}
                </a>
            </div>
            <h4>{props.title}</h4>
            <div className="text">
                {props.text}
            </div>
            <p>
                <a href={props.url} target="_blank" rel="noopener noreferrer">
                    {props.url}
                    {props.url? 
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-external-link" width="18" height="18" viewBox="0 0 24 24" strokeWidth="1.8" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <desc>Download more icon variants from https://tabler-icons.io/i/external-link</desc>
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5"></path>
                            <line x1="10" y1="14" x2="20" y2="4"></line>
                            <polyline points="15 4 20 4 20 9"></polyline>
                        </svg>
                    : null}
                </a>
            </p>

        </div>
    )
}


export default function Works() {
    const workData = [
        {
            img: "3.jpg",
            title: "Shopi",
            url: "https://shopihq.com",
            text: "Frontend"
        },
        {
            img: "2.jpg",
            title: "Frank Digital",
            url: "https://www.frank.com.tr",
            text: "Frontend"
        },
        {
            img: "4.jpg",
            title: "Finapp Wallet & Banking",
            url: "https://finapp.bragherstudio.com",
            text: "UI Design & Frontend"
        },
        {
            img: "5.jpg",
            title: "Mobilekit UI Kit",
            url: "https://mobilekit.bragherstudio.com",
            text: "UI Design & Frontend"
        },
        {
            img: "1.jpg",
            title: "Derby",
            url: "https://www.derby.com.tr",
            text: "Frontend"
        },
        {
            img: "6.jpg",
            title: "LoL Guide App",
            text: "UI Design & Frontend"
        },


    ]
    const works = workData.map((i) => {
        return (
            <WorkCard
                key={i.img}
                img={"img/work/" + (i.img)}
                title={i.title}
                text={i.text}
                url={i.url}
            />
        )
    });
    return (
        <section id="works">
            <div className="container">
                <h2>Lastest works</h2>
                <div className="item-wrapper">
                    {works}
                </div>
            </div>
        </section>
    )
}
