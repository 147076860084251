export default function Header() {

    return (
        <header id="navbar">
            <div className="container">
                <div className="logo">
                    <a href="/" className="logo">./egemenk</a>
                </div>
                <ul className="nav">
                    <li>
                        <a href="#about">About</a>
                    </li>
                    <li>
                        <a href="#works">Works</a>
                    </li>
                </ul>
            </div>
        </header>
    )
}
