import Github from "../icon/github"
import Linkedin from "../icon/linkedin"

export default function Hero() {
    return (
        <section id="about">
            <div className="container">
                <div className="hero-content">
                    <h4 className="subtitle">
                        Hi ✋&#44; I&apos;m Egemen Kapusuz
                    </h4>
                    <h1 className="title">
                        Front-end Developer
                    </h1>
                    <p className="text">
                        I&apos;ve been a developer for 12 years.
                        I&apos;ve worked with a variety of technologies and languages.
                        I have worked in projects such as <b>E-commerce</b>&#44; <b>Fintech</b>&#44; <b>Booking</b>&#44; <b>Mobile Web</b>.
                    </p>
                    <div className="social-profiles">
                        <a
                            href="mailto:egemenkapusuz@gmail.com"
                            className="text-button"
                        >
                            E-mail
                        </a>
                        <a href="https://www.github.com/eg/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Github
                                width="24"
                                height="24"
                                color="#2D2D2D"
                            />
                        </a>
                        <a href="https://www.linkedin.com/in/egemenk/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Linkedin
                                width="24"
                                height="24"
                                color="#2D2D2D"
                            />
                        </a>

                    </div>
                </div>
                <div className="hero-media">
                    <div className="hero-image">
                        <img src="img/profile-cover.jpg" alt="heroimg" className="hero-img"/>
                    </div>
                </div>

            </div>
        </section>
    )
}
