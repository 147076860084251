import './styles/style.css';
import Header from './components/Header';
import Hero from './components/Hero';
import Works from './components/Works';
function App() {
  return (
    <>
      <Header />
      <Hero/>
      <Works/>
    </>
  );
}

export default App;
